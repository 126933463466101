import axios from "@evercam/shared/api/client/axios"
import { toQueryString } from "@evercam/shared/utils"
import type {
  AnprQueryParams,
  AnprEvent,
  EventCountRequestPayload,
  EventCountResponsePayload,
  EventsExportRequestParameters,
  EventsRequestPayload,
  EventsResponsePayload,
  GateReportROI,
  PaginatedItems,
  GateReportVerifiedDay,
  GateReportEventsDetailedCountsPayload,
  ProjectExid,
  CameraExid,
  GateReportDetailedEventsCount,
  AnprCountsQueryParams,
  AnprCount,
  AnprBasedGateReportExportRequestParams,
  GateReportResponsePayload,
  GatReportRequestPayload,
} from "@evercam/shared/types"
import type { AxiosRequestConfig } from "axios"
import { VEHICLE_TYPE_IDS } from "@evercam/shared/constants/gateReport"

export const getAiApiUrl = () => {
  if (axios.env.isStaging) {
    return axios.env.stagingAiApiUrl
  }

  return axios.env.aiApiUrl
}

export const AiApi = {
  analyticsParameters: {
    getParameters(cameraId: CameraExid, params = {}) {
      return axios.get(
        `${getAiApiUrl()}/cameras/${cameraId}/analytics-parameters`,
        {
          params,
        }
      )
    },
    createParameters(params = {}) {
      return axios.post(`${getAiApiUrl()}/cameras/analytics-parameters`, params)
    },
    updateParameters({ updatedBy, id, ...payload }) {
      return axios.patch(
        `${getAiApiUrl()}/cameras/analytics-parameters/${id}?${toQueryString({
          updatedBy,
        })}`,
        payload
      )
    },
  },
  anpr: {
    exportAnprLegacyEvents(projectExid: ProjectExid, params: AnprQueryParams) {
      return axios({
        url: `${getAiApiUrl()}/projects/${projectExid}/anpr/export?${toQueryString(
          params
        )}`,
        method: "GET",
        responseType: "blob",
      })
    },
    getAnprEvents(
      projectExid: ProjectExid,
      params: AnprQueryParams
    ): Promise<PaginatedItems<AnprEvent>> {
      return axios.get(
        `${getAiApiUrl()}/projects/${projectExid}/anpr?${toQueryString(params)}`
      )
    },
    getAnprCounts(
      projectExid: ProjectExid,
      payload: AnprCountsQueryParams
    ): Promise<AnprCount[]> {
      return axios.get(
        `${getAiApiUrl()}/projects/${projectExid}/anpr/counts?${toQueryString(
          payload
        )}`
      )
    },
    updateAnprEvent(anprEventId, eventBody) {
      return axios.patch(`${getAiApiUrl()}/projects/anpr/${anprEventId}`, {
        ...eventBody,
      })
    },
    bulkUpdateAnprRecords(anprEventsIds, action) {
      return axios.patch(
        `${getAiApiUrl()}/projects/anpr/update/status?${toQueryString({
          anprEventsIds,
          action,
        })}`
      )
    },
    exportAnprEvents(
      projectExid: string,
      params: AnprBasedGateReportExportRequestParams
    ) {
      return axios({
        url: `${getAiApiUrl()}/projects/${projectExid}/anpr/events/export?${toQueryString<AnprBasedGateReportExportRequestParams>(
          params
        )}`,
        method: "GET",
        responseType: "blob",
      })
    },
  },
  gateReport: {
    getGateReportProjects(params) {
      return axios.get(
        `${getAiApiUrl()}/projects/gate-report/get-projects-data`,
        params
      )
    },
    getLastVerified(params) {
      return axios.get(
        `${getAiApiUrl()}/projects/gate-report/last-verified`,
        params
      )
    },
    deleteEvent(id, params) {
      return axios.delete(
        `${getAiApiUrl()}/projects/events/${id}?${toQueryString(params)}`
      )
    },
    dismissEvents(params) {
      return axios.delete(
        `${getAiApiUrl()}/projects/events?${toQueryString(params)}`
      )
    },
    exportEvents(projectExid: string, params: EventsExportRequestParameters) {
      return axios({
        url: `${getAiApiUrl()}/projects/${projectExid}/gate-report/events/export?${toQueryString<EventsExportRequestParameters>(
          params
        )}`,
        method: "GET",
        responseType: "blob",
      })
    },
    exportEventsProgress(projectExid: string, params: { exportDate: string }) {
      return axios.get(
        `${getAiApiUrl()}/projects/${projectExid}/gate-report/events/export/progress?${toQueryString(
          params
        )}`
      )
    },
    getAllEvents(
      projectExid: ProjectExid,
      params: EventsRequestPayload,
      extraParams?: AxiosRequestConfig
    ): Promise<EventsResponsePayload> {
      if (!params.vehicleTypes) {
        params.vehicleTypes = VEHICLE_TYPE_IDS
      }
      if (!params.truckTypes) {
        params.vehicleTypes = VEHICLE_TYPE_IDS
      }
      if (!params.camerasExid) {
        params.camerasExid = params.cameras
      }

      return axios.get(
        `${getAiApiUrl()}/projects/${projectExid}/events?${toQueryString(
          params
        )}`,
        extraParams
      )
    },
    getDetailedEventCounts(
      projectExid: ProjectExid,
      params: GateReportEventsDetailedCountsPayload,
      extraParams?: AxiosRequestConfig
    ): Promise<GateReportDetailedEventsCount[]> {
      return axios.get(
        `${getAiApiUrl()}/projects/${projectExid}/gate-report/event-counts?${toQueryString(
          params
        )}`,
        extraParams
      )
    },
    getEventCounts(
      projectExid: string,
      payload: EventCountRequestPayload = {},
      extraParams?: AxiosRequestConfig
    ): Promise<EventCountResponsePayload> {
      if (!payload.vehicleTypes) {
        payload.vehicleTypes = VEHICLE_TYPE_IDS
      }

      return axios.get(
        `${getAiApiUrl()}/projects/${projectExid}/project-stats?${toQueryString(
          payload
        )}`,
        {
          ...(extraParams || {}),
        }
      )
    },
    getVerifiedDays(
      projectExid,
      params,
      extraParams = {}
    ): Promise<PaginatedItems<GateReportVerifiedDay>> {
      return axios.get(
        `${getAiApiUrl()}/projects/${projectExid}/gate-report/verified-days?${toQueryString(
          params
        )}`,
        extraParams
      )
    },
    updateEventById(eventId, { updatedBy, payload }) {
      return axios.patch(
        `${getAiApiUrl()}/projects/events/${eventId}?${toQueryString({
          updatedBy,
        })}`,
        payload
      )
    },
    verifyDay(params) {
      return axios.post(
        `${getAiApiUrl()}/projects/gate-report/verified-days`,
        params
      )
    },
    regenerateThumbnail(id, params) {
      return axios.put(
        `${getAiApiUrl()}/projects/gate-report/event-thumbnail/render/${id}?${toQueryString(
          params
        )}`,
        {
          method: "PUT",
        }
      )
    },
    getDailyStatus(projectExid, params = {}) {
      return axios.get(
        `${getAiApiUrl()}/projects/${projectExid}/processed-days`,
        { params }
      )
    },
    getEventThumbnail({ cameraExid, eventType, eventTime, token }) {
      return `${getAiApiUrl()}/cameras/${cameraExid}/gate-report/${eventType}/${eventTime}/thumbnail?authorization=${token}`
    },
    getEventSnapshot({ cameraExid, eventType, eventTime, token }) {
      return `${getAiApiUrl()}/cameras/${cameraExid}/gate-report/${eventType}/${eventTime}/snapshot?authorization=${token}`
    },
    getEvents(
      projectExid: ProjectExid,
      params: GatReportRequestPayload,
      extraParams: AxiosRequestConfig = {}
    ): Promise<GateReportResponsePayload> {
      return axios.get(
        `${getAiApiUrl()}/projects/${projectExid}/gate-report/events?${toQueryString(
          params
        )}`,
        extraParams
      )
    },
    getCounts(
      projectExid: string,
      payload: EventCountRequestPayload = {},
      extraParams: AxiosRequestConfig = {}
    ): Promise<EventCountResponsePayload> {
      return axios.get(
        `${getAiApiUrl()}/projects/${projectExid}/gate-report/counts?${toQueryString(
          payload
        )}`,
        extraParams
      )
    },
  },
  brainTool: {
    getObjectDetectionsResults(cameraExid, params = {}) {
      return axios.post(
        `${getAiApiUrl()}/cameras/${cameraExid}/ai-models/object-detection?${toQueryString(
          params
        )}`
      )
    },
    getSecurityMesurement(cameraExid, params = {}) {
      return axios.post(
        `${getAiApiUrl()}/cameras/${cameraExid}/ai-models/chatgpt?${toQueryString(
          params
        )}`
      )
    },
    getSegments(cameraExid: string, params = {}) {
      return axios.post(
        `${getAiApiUrl()}/cameras/${cameraExid}/ai-models/sam?${toQueryString(
          params
        )}`
      )
    },
    async getDepth(cameraExid, timestamp) {
      return axios.post(`${getAiApiUrl()}/depth-estimation`, {
        cameraex: cameraExid,
        timestamp,
      })
    },
  },
  roi: {
    getROIs(projectExid, params = {}): Promise<GateReportROI[]> {
      return axios.get(
        `${getAiApiUrl()}/projects/${projectExid}/rois?${toQueryString(params)}`
      )
    },
    createROI(params) {
      return axios.post(`${getAiApiUrl()}/projects/rois`, params)
    },
    updateROI({ updatedBy, roi }) {
      return axios.patch(
        `${getAiApiUrl()}/projects/rois/${roi.id}?${toQueryString({
          updatedBy,
        })}`,
        roi
      )
    },
  },
}
